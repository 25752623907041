<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="mt-2">
                <b-col cols="12" md="12">
                    <ValidationProvider name="file"
                                        rules=""
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('certificate')">
                            <b-form-file
                                v-model="formData.file"
                                :state="errors[0] ? false : null"
                                placeholder=""
                                drop-placeholder=""
                            ></b-form-file>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <b-button variant="primary"
                                  @click="createForm"
                                  :disabled="formLoading">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </b-col>

            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
// Components


// Services
import CertificateService from "@/services/CertificateService";

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';


export default {
    props:{
        student_certificate_id: {}
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formData: {
                file:null,
                student_certificate_id: this.student_certificate_id
            },
            formLoading: false
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                let formData = new FormData();
                formData.append("file",this.formData.file)
                formData.append("student_certificate_id",this.formData.student_certificate_id)


                CertificateService.uploadPdf(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    })
            }
            else {
                let errors = this.$refs.formModalValidate.errors;
            }
        }
    }
}
</script>

